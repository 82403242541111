:root {
  --primary-color: #0096c8;
  /* --primary-color: #74c0fc; */
  --secondary-color: #0154a4;
  /* --secondary-color: #183153; */
  --white: #ffffff;
  --gray: #c3c6d1;
  --bluish-gray: #616d8a;
}

body {
  margin: 0;
  padding: 0;
}

/* App Header Styles: Start */
.App-header {
  color: var(--secondary-color) !important;
}

.App-header .nav-link {
  color: var(--secondary-color) !important;
}

.App-header .nav-link:hover {
  color: var(--primary-color) !important;
  /* text-decoration: underline !important; */
}

.App-header .nav-item .nav-link {
  border-bottom: 5px solid transparent;
  transition: border-bottom 0.5s ease;
  /* Additional styling if needed */
  text-decoration: none;
  color: inherit;
}

.App-header .nav-item:hover .nav-link {
  /* color: var(--primary-color) !important; */
  border-bottom: 5px solid var(--primary-color) !important;
  transition: all;
}

/* .App-header .nav-link.active {
  color: var(--primary-color) !important;
} */

.shs-btn {
  border-radius: 0 !important;
  color: var(--white) !important;
  background-color: var(--secondary-color) !important;
}
/* App Header Styles: End */

/* App Main Styles: Start */
.App-main {
  border: initial;
  /* background-image: linear-gradient(-90deg, #f5f5f5 0%, #f5f5f5 100%); */
  /* background-image: linear-gradient(-90deg, #a3278f 0%, #e52f50 100%); */
  /* color: var(--secondary-color) !important;
  background-color: var(--white);
  background-image: url(https://inspiredgracehealthcare.com/wp-content/uploads/2022/09/bg-1.svg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.52;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s; */
}
/* App Main Styles: End */

/* App Footer Styles: Start */
.App-footer {
  border: initial;
  color: var(--secondary-color);
  box-shadow: inset 0px 10px 20px -10px rgba(0, 0, 0, 0.1);
}

.App-footer .list-group-item {
  color: var(--secondary-color);
  background-color: var(--white);
}

.App-footer .nav-link,
.App-footer ul.list-group {
  color: var(--secondary-color) !important;
}

.App-footer .nav-link:hover,
a.footer-link:hover {
  text-decoration: underline !important;
}

.App-footer i.fa-brands:hover {
  color: var(--primary-color) !important;
}

a.footer-link {
  color: var(--secondary-color);
  text-decoration: none;
}

.App-footer .fa-solid,
.App-footer .fa-brands {
  font-size: 20px !important;
}

.App-footer .row h5 {
  color: var(--bluish-gray) !important;
}
/* App Footer Styles: End */

.navbar {
  /* height: 7vh; */
  box-shadow: inset 0px -10px 10px -10px rgba(0, 0, 0, 0.1);
}

/* Links: Start */
.navbar .nav-link {
  font-size: 16px !important;
  font-weight: bold !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
/* Links: End */

/* Newletter */
.newsletter {
  background-color: var(--secondary-color);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}
.btn-subscribe,
input[id="newsletterInput"] {
  color: var(--white) !important;
  background-color: var(--bluish-gray) !important;
  border-radius: 0 !important;
  border: 1px solid var(--white) !important;
}
input[id="newsletterInput"]::placeholder {
  color: var(--white) !important;
}
input[id="newsletterInput"] {
  border-right: 0 !important;
  border-top-left-radius: 35px !important;
  border-bottom-left-radius: 35px !important;
}
.btn-subscribe {
  border-left: 0 !important;
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
}
.btn-subscribe:hover,
input[id="newsletterInput"]:hover {
  opacity: 80%;
}
/* Newletter */

/* Forms */
.importantInput {
  color: red;
  font-size: 16px;
  font-weight: bold;
}
.form-control,
.form-select {
  border: 1px solid var(--secondary-color);
}
form button[type="submit"] {
  background-color: var(--secondary-color) !important;
}
form button[type="submit"]:hover {
  opacity: 80% !important;
}
input#phone {
  border-right: none !important;
}
span.input-group-text {
  background-size: contain !important;
  background-color: var(--white) !important;
  background-image: url(../images/usa.png) !important;
  background-repeat: no-repeat;
  background-position: center !important;
  border: 1px solid var(--secondary-color) !important;
  border-left: none !important;
}
/* Forms */

p {
  font-size: larger;
}

.review.fa-star {
  color: orange;
}

.chevron {
  color: var(--secondary-color);
  font-size: 2rem;
}

.review-indicators > button {
  background-color: var(--secondary-color) !important;
  width: 20px !important;
  height: 20px !important;
}

@media (max-width: 768px) {
  #welcomeSlider h1 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

i.fa-solid.fa-bars-staggered::before,
i.fas.fa-xmark::before {
  font-size: 2rem;
}
