.contact-phone a,
.contact-email a,
.contact-map a {
  color: var(--secondary-color) !important;
  text-decoration: none !important;
  font-weight: bold;
  /* font-size: 2rem; */
}

.contact-phone a:hover,
.contact-email a:hover,
.contact-map a:hover {
  color: var(--primary-color) !important;
}
