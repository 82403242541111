.careers .card {
  width: 18rem;
  color: var(--secondary-color) !important;
  background-color: var(--gray) !important;
  border-radius: 0 !important;
}

.careers .card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.careers .card .fas {
  font-size: 45px;
  border-radius: 50% !important;
  padding: 50px !important;
  color: var(--white) !important;
  background-color: var(--secondary-color) !important;
}

.careers .career-btn {
  color: var(--white) !important;
  background-color: var(--bluish-gray) !important;
  border-radius: 0 !important;
}

.careers .career-btn:hover {
  color: var(--white) !important;
  background-color: var(--secondary-color) !important;
}
