.carousel-indicators button[data-bs-target="#welcomeSlider"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.carousel-indicators button[class="active"] {
  background-color: var(--secondary-color) !important;
}

.container-fluid.m-0.p-0 {
  background-image: url("../../assets/images/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  a.shs-btn {
    margin: auto auto 30px;
  }
}
/* h1.fw-bold + p {
  font-size: initial !important;
} */

.icon-circle {
  color: var(--white);
  background-color: var(--secondary-color);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 20px;
}

.icon-circle .fa-solid {
  font-size: 1.5rem;
}
